<template>
    
</template>

<script>
export default {
  data() {
    return {
      code:'', // 微信回调coed
    }
  },
  created(){
    let code = this.$route.query.code
    if (code){
      this.login(code)
    }else{
      return
    }
  },
  mounted(){
  },
  methods: {
    login(code){
        this.$api.login.pcLogin({code:code}).then(res => {
          console.log(res);
          if (res.ret === 200){
            // token 
            if (res.data.accessToken){ //&& res.data.user_info
                window.sessionStorage.setItem('token',res.data.accessToken)
                window.sessionStorage.setItem('user_info',JSON.stringify(res.data.userInfo))
                // 跳转
                top.location.href = (window.location.origin+'/home')
            }else{
              top.location.href = (window.location.origin+'/login')
            }
          }
        })
    }
  }
}
</script>

<style>

</style>